<template>
  <div id="infinite-list">
    <!--    <v-card>-->
    <!--      <v-card-title>-->
    <!--        <v-btn-->
    <!--          id="btn1"-->
    <!--          v-can="'post.create'"-->
    <!--          color="primary"-->
    <!--          dark-->
    <!--          @click="dialogModal(true, 'create')"-->
    <!--        >-->
    <!--          <v-icon dark>-->
    <!--            mdi-plus-->
    <!--          </v-icon>-->
    <!--          {{ $('create') }}-->
    <!--        </v-btn>-->
    <!--        <v-spacer></v-spacer>-->
    <!--      </v-card-title>-->
    <!--    </v-card>-->
    <v-row style="width: 50vw; margin: 0 auto">
      <v-col
        v-for="post in items"
        :key="post.id"
        cols="12"
      >
        <v-card>
          <v-card-title>
            <div class="d-flex">
              <v-avatar size="38">
                <v-img
                  src="@/assets/images/avatars/teacher.png"
                ></v-img>
              </v-avatar>
              <div class="text-no-wrap ms-3">
                <p class="font-weight-medium mb-0 text--primary">
                  {{ post.teacher ? post.teacher.name : 'ادارة النظام' }}
                </p>
                <span v-if="post.stage" class="text-xs">{{ post.stage.name }}</span>
                <span v-if="post.subject" class="text-xs"> - {{ post.subject.name }}</span>
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  small
                  icon
                  color="dark"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <!--<menu-item :label="$('students')" color="primary" icon="mdi-account-school" @click="$router.push({name: 'student', params: { postId: item.id }})"></menu-item>-->
                <!--                <menu-item :label="$('update')" color="info" icon="mdi-pencil" @click="dialogModal(true, 'update', post.id)"></menu-item>-->
                <menu-item :label="$('delete')" color="error" icon="mdi-delete" @click="dialogModal(true, 'delete', post.id)"></menu-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-text>
            {{ post.body }}
          </v-card-text>
          <v-img
            v-if="post.image"
            :src="post.image"
            height="250"
          />
        </v-card>
      </v-col>
      <v-col v-if="loading" cols="12">
        <div class="text-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
    <action-dialog
      :dialog-modal="dialogModal"
      :data="dialogData"
      :translation-key="translationKey"
      @update:table="loadMore(true)"
    ></action-dialog>
  </div>
</template>

<script>
import axios from '@axios'
import ActionDialog from './dialog.vue'

import { ObjectToQuery } from '@/plugins/helper'
import MenuItem from '@/components/menu/menuItem.vue'

export default {
  name: 'Posts',
  components: {
    ActionDialog,
    MenuItem,
  },
  data: () => ({
    translationKey: 'pages.post',
    items: [],
    loading: false,
    hasMore: true,
    perPage: 20,
    page: 1,
    dialogData: {
      dialog: false,
      type: 'create',
      id: null,
    },
  }),
  mounted() {
    this.loadMore()
    const listElm = window
    listElm.addEventListener('scroll', () => {
      if (listElm.scrollY >= (document.body.offsetHeight - window.outerHeight) && !this.loading && this.hasMore) {
        this.loadMore()
      }
    })
  },
  methods: {
    async loadMore(reload = false) {
      try {
        this.loading = true
        if (reload) {
          this.page = 1
          this.items = []
        }
        const query = ObjectToQuery({
          perPage: this.perPage,
          page: this.page,
        })
        const response = await axios.get(`/posts?${query}`)
        const posts = response.data
        if (posts.length < this.perPage) this.hasMore = false
        if (reload) {
          this.items = posts
        } else {
          this.items.push(...posts)
        }
        this.page += 1
      } finally {
        this.loading = false
      }
    },
    dialogModal(dialog, type = this.dialogData.type, id = null) {
      this.dialogData.dialog = dialog
      this.dialogData.type = type
      this.dialogData.id = id
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.${key}`, parameters)
    },
  },

}
</script>
